import './App.css';
import { useState, useEffect } from 'react';

const AnimatedCounter = () => {
  const [count, setCount] = useState(0);
  const [percentCount, setPercentCount] = useState(0);
  const targetNumber = 3000000;
  const targetPercent = 100;
  const duration = 4000;

  useEffect(() => {
    let startTime = null;
    
    const animate = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const progress = (currentTime - startTime) / duration;

      if (progress < 1) {
        const easeOutExpo = 1 - Math.pow(1 - progress, 5);
        setCount(Math.floor(targetNumber * easeOutExpo));
        setPercentCount(Math.floor(targetPercent * easeOutExpo));
        requestAnimationFrame(animate);
      } else {
        setCount(targetNumber);
        setPercentCount(targetPercent);
      }
    };

    requestAnimationFrame(animate);
  }, []);

  return (
    <div className="counter-section">
      <div className="counter-container">
        <div className="counter-value">{count.toLocaleString()}+</div>
        <div className="counter-label">$ByeGary rewarded to wack-a-gary contest winners</div>
      </div>
      <div className="counter-divider"></div>
      <div className="counter-container">
        <div className="counter-value">{percentCount}%</div>
        <div className="counter-label">liquidity locked (permalock dec 9th)</div>
      </div>
    </div>
  );
};


function App() {
  const [copied, setCopied] = useState(false);
  const [subtextState, setSubtextState] = useState(0);
  const contractAddress = "0x39e6EED85927e0203c2ae9790eDaeB431B8e43c1";

  const subtextImages = [
    '/subtext.png',
    '/subtext2.png'
  ];

  const handleSubtextClick = async () => {
    try {
      await navigator.clipboard.writeText(contractAddress);
      setCopied(true);
      
      const nextIndex = (subtextState + 1) % subtextImages.length;
      const img = new Image();
      img.src = subtextImages[nextIndex];
      
      img.onload = () => {
        setSubtextState(nextIndex);
      };

      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  useEffect(() => {
    subtextImages.forEach(src => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  const icons = [
    { src: '/dexscreener.svg', alt: 'DEX Screener', text: 'DEX Screener', link: 'https://dexscreener.com/base/0xbc96cccf0ebb14ad150d358460d03c79b2b90d8a' },
    { src: '/x.svg', alt: 'X (Twitter)', text: 'Twitter', link: 'https://x.com/byegaryonbase' },
    { src: '/telegram.svg', alt: 'Telegram', text: 'Telegram', link: 'https://t.me/byegaryonbase' },
    { src: '/dextools.svg', alt: 'Dextools', text: 'Dextools', link: 'https://www.dextools.io/app/en/base/pair-explorer/0xbc96cccf0ebb14ad150d358460d03c79b2b90d8a?t=1731833558989' },
    { src: '/farcaster.svg', alt: 'Farcaster', text: 'Farcaster', link: 'https://warpcast.com/~/channel/byegary' },
    { src: '/basescan.svg', alt: 'BaseScan', text: 'BaseScan', link: 'https://basescan.org/token/0x39e6EED85927e0203c2ae9790eDaeB431B8e43c1' }
  ];

  return (
    <div className="App">
      <div className="glow-overlay"></div>
      <div className="content">
        <div className="text-container">
          <img src="/title.png" alt="ByeGary" className="title-image" />
        </div>

        <div className="hint-text">Click on the image to learn more about the origin👇</div>

        <a
          href="https://warpcast.com/maretus.eth/0x5c6c40d1"
          target="_blank"
          rel="noopener noreferrer"
          className="foreground-link"
        >
          <img
            src="/foreground-2.png"
            alt="Logo"
            className="foreground-image"
          />
        </a>

        <div className="text-container">
          <img 
            src={subtextImages[subtextState]}
            alt="Contract Address" 
            className="subtext-image"
            onClick={handleSubtextClick}
          />
          {copied && <span className="copy-feedback">Copied!</span>}
        </div>

        <AnimatedCounter />

        <div className="icons-container">
          {icons.map((icon, index) => (
            <div key={index} className="icon-wrapper">
              <a
                href={icon.link}
                className="icon-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={icon.src}
                  alt={icon.alt}
                  className="custom-icon"
                />
                <span className="icon-text">{icon.text}</span>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
